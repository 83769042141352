<template>
  <div class="designer-concern-pannel">
    <div class="common-title">我的关注</div>
    <div class="content">
      <div class="list-wrapper" v-loading="pageLoading">
        <!-- <div class="div-scroll"> -->
        <ul
          class="follow-list"
          v-infinite-scroll="loadMore"
          :infinite-scroll-disabled="busy"
        >
          <li
            v-for="item in list"
            :key="item.id"
            @click="$router.push('/community/designer/' + item.id)"
          >
            <div class="li-content">
              <el-avatar :size="100" :src="item.photo || circleUrl"></el-avatar>
              <div class="name">{{ item.nickname }}</div>
              <div class="works">
                <div class="folder">
                  <p>{{ item.directoryCount }}</p>
                  <p>作品集</p>
                </div>
                <div class="images">
                  <p>{{ item.resourceCount }}</p>
                  <p>作品</p>
                </div>
              </div>
              <div
                class="concern-btn-plain"
                @click.stop="handleModifyConcern(item)"
              >
                <span>已关注</span>
              </div>
            </div>
          </li>
        </ul>

        <Empty v-if="isEmpty" description="您还没有关注的设计师！"/>
        <!-- </div> -->
      </div>
      <!-- <div class="page">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="pageParams.total"
          :current-page="pageParams.currentPage"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      SUCCESS_CODE: 0,
      circleUrl:
        "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
      list: [],
      pageParams: {
        total: 0,
        pageNum: 1,
        pageSize: 20,
        currentPage: 0,
      },
      pageLoading: false,
      isEmpty: false,
      busy: false,
    };
  },
  created() {
    this.getData(true);
  },
  methods: {
    // 获取关注列表
    getData(flag) {
      if (flag) {
        this.list = [];
        this.pageParams = {
          total: 0,
          pageNum: 1,
          pageSize: 20,
          currentPage: 0,
        };
      }
      if (this.busy) return;
      if (
        this.pageParams.currentPage * this.pageParams.pageSize >
        this.pageParams.total
      ) {
        return;
      }
      this.pageLoading = true;
      this.busy = true;
      this.$http
        .get("/sys-user/follows", { params: this.pageParams })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.list = this.list.concat(res.data.records);
            this.pageParams.total = res.data.total;
            this.pageParams.currentPage = res.data.current; 
            if (this.list && this.list.length) {
              this.isEmpty = false;
            } else {
              this.isEmpty = true;
            }
          } else {
            this.$message.error("获取数据失败！");
          }
          this.pageLoading = false;
          this.busy = false;
        })
        .catch((err) => {
          this.pageLoading = false;
          this.busy = false;
          this.$message.error("获取数据失败！");
        });
    },
    loadMore() {
      this.pageParams.pageNum++;
      this.getData();
    },
    handleCurrentChange(current) {
      this.pageParams.pageNum = current;
      thsi.getData();
    },
    // 关注/取消关注设计师
    handleModifyConcern(data) {
      if (data.follow) {
        this.$confirm("此操作取消对该设计师的关注, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
          center: true,
        })
          .then(() => {
            this.modifyConcern(data);
          })
          .catch(() => {});
      } else {
        this.modifyConcern(data);
      }
    },
    modifyConcern(data) {
      let params = {
        id: data.id,
        status: 0,
      };
      this.$http
        .post("/relation/modify", null, { params })
        .then((res) => {
          if (res.code == this.SUCCESS_CODE) {
            this.getData(true);
            this.$parent.getUserScore();
            this.$message.success("取消关注成功！");
          } else {
            this.$message.error("取消关注失败！");
          }
        })
        .catch((err) => {
          this.$message.error("取消关注失败！");
        });
    },
  },
};
</script>
